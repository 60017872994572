<template>
    <div class="px-4 pb-6">
        <v-dialog v-model="addDataDialog" persistent max-width="600">
            <v-card outlined elevation="3" class="px-14 py-14">
                <v-form ref="form" v-model="valid">
                    <v-row>
                        <v-col cols="4" class="pa-0">
                            <v-card-title
                                class="pa-0 text-subtitle-1 font-weight-bold black--text"
                            >
                                Kode Material
                            </v-card-title>
                        </v-col>
                        <v-col cols="6" class="pa-0">
                            <v-select
                                v-model="form.kode"
                                dense
                                outlined
                                placeholder="Pilih Kode Material"
                                :items="listMatCode"
                                item-text="desc"
                                item-value="kode"
                                return-object
                                :rules="[rules.required]"
                                :error="!!errors.kode"
                                :error-messages="errors.kode"
                                v-on:change="autoDisplayPackage"
                            >
                            </v-select>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="4" class="pa-0">
                            <v-card-title
                                class="pa-0 text-subtitle-1 font-weight-bold black--text"
                            >
                                Tanggal Produksi
                            </v-card-title>
                        </v-col>
                        <v-col cols="6" class="pa-0">
                            <v-menu
                                v-model="dateMenu"
                                :close-on-content-click="false"
                                :nudge-left="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="form.manufacturing_date"
                                        append-icon="mdi-calendar-month"
                                        placeholder="dd/mm/yyyy"
                                        readonly
                                        dense
                                        v-bind="attrs"
                                        v-on="on"
                                        outlined
                                        :rules="[rules.required]"
                                        :error="!!errors.manufacturing_date"
                                        :error-messages="
                                            errors.manufacturing_date
                                        "
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    ref="picker"
                                    v-model="manufacturing_date"
                                    @input="dateMenu = false"
                                    v-on:change="applyDateChange"
                                >
                                </v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="4" class="pa-0">
                            <v-card-title
                                class="pa-0 text-subtitle-1 font-weight-bold black--text"
                            >
                                Masa Kadaluarsa
                            </v-card-title>
                        </v-col>
                        <v-col cols="4" class="pa-0">
                            <v-text-field
                                placeholder="Masa Kadaluarsa"
                                outlined
                                dense
                                type="number"
                                :rules="[rules.required]"
                                :error="!!errors.shelf_life"
                                :error-messages="errors.shelf_life"
                                v-model="form.shelf_life"
                                v-on:change="dateExpiredChange"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="3" class="pa-0" v-if="false">
                            <v-select
                                v-model="form.shelf_life"
                                dense
                                outlined
                                placeholder="Masa Kadaluarsa"
                                :items="listMasaKadaluarsa"
                                item-text="text"
                                item-value="id"
                                :rules="[rules.required]"
                                v-on:change="dateExpiredChange"
                            ></v-select>
                        </v-col>
                        <v-col cols="2" class="pa-0 pl-2 pt-2">
                            <v-card-title
                                class="pa-0 text-subtitle-2 black--text"
                            >
                                Bulan
                            </v-card-title>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="4" class="pa-0">
                            <v-card-title
                                class="pa-0 text-subtitle-1 font-weight-bold black--text"
                            >
                                Tanggal Expired
                            </v-card-title>
                        </v-col>
                        <v-col cols="6" class="pa-0">
                            <v-menu
                                :close-on-content-click="false"
                                :nudge-left="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="form.expired_date"
                                        append-icon="mdi-calendar-month"
                                        placeholder="dd/mm/yyyy"
                                        readonly
                                        disabled
                                        filled
                                        dense
                                        v-bind="attrs"
                                        v-on="on"
                                        outlined
                                        :rules="[rules.required]"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    ref="picker"
                                    v-model="form.expired_date"
                                >
                                </v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="4" class="pa-0">
                            <v-card-title
                                class="pa-0 text-subtitle-1 font-weight-bold black--text"
                            >
                                Total Box / Sleeve
                            </v-card-title>
                        </v-col>

                        <v-col cols="4" class="pa-0">
                            <v-text-field
                                placeholder="Total Box / Sleeve"
                                outlined
                                dense
                                type="number"
                                :rules="[rules.required]"
                                :error="!!errors.total_box_sleeve"
                                :error-messages="errors.total_box_sleeve"
                                v-model="form.total_box_sleeve"
                                v-on:change="autoDisplayPackage"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="4" class="pa-0">
                            <v-card-title
                                class="pa-0 text-subtitle-1 font-weight-bold black--text"
                            >
                                Jumlah MC
                            </v-card-title>
                        </v-col>
                        <v-col cols="4" class="pa-0">
                            <v-text-field
                                placeholder="Jumlah MC"
                                disabled
                                filled
                                outlined
                                dense
                                type="number"
                                v-model="form.total_mc"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="4" class="pa-0">
                            <v-card-title
                                class="pa-0 text-subtitle-1 font-weight-bold black--text"
                            >
                                Jumlah IC
                            </v-card-title>
                        </v-col>

                        <v-col cols="4" class="pa-0">
                            <v-text-field
                                placeholder="Jumlah IC"
                                disabled
                                filled
                                outlined
                                dense
                                type="number"
                                v-model="form.total_ic"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="4" class="pa-0">
                            <v-card-title
                                class="pa-0 text-subtitle-1 font-weight-bold black--text"
                            >
                                Jumlah Kapsul
                            </v-card-title>
                        </v-col>
                        <v-col cols="4" class="pa-0">
                            <v-text-field
                                placeholder="Jumlah Kapsul"
                                disabled
                                filled
                                outlined
                                dense
                                type="number"
                                v-model="form.total_capsule"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row class="mt-5">
                        <v-col cols="12" class="pa-0" align="right">
                            <v-btn
                                color="secondary"
                                class="text-subtitle-1 white--text mx-1"
                                style="text-transform: unset !important"
                                :loading="false"
                                @click.prevent="$emit('closeDataDialog')"
                            >
                                Cancel
                            </v-btn>
                            <v-btn
                                :disabled="!form.total_capsule"
                                color="#61B15A"
                                class="text-subtitle-1 white--text mx-1"
                                style="text-transform: unset !important"
                                :loading="false"
                                @click.prevent="dialogGenerate = true"
                            >
                                <v-icon left>mdi-content-save-check</v-icon>
                                Save
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card>
        </v-dialog>

        <v-dialog v-model="progress" width="500">
            <v-card>
                <v-card-text class="pt-5">
                    <v-progress-linear
                        indeterminate
                        color="#FFA900"
                    ></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog persistent v-model="modalDialog" width="430">
            <v-card color="white" rounded>
                <v-card-title class="text-h5 black--text">
                    {{ modalTitle }}
                </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="light-green darken-1"
                        class="white--text"
                        @click="closeModalDialog"
                    >
                        Tutup
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogGenerate" persistent max-width="500px">
            <v-card>
                <v-card-title class="pa-2 text-h6 font-weight-bold black--text">
                    Confirmation
                </v-card-title>
                <v-divider></v-divider>
                <v-card-subtitle class="pa-4 text-subtitle-2 black--text">
                    Generate Barcode ?
                </v-card-subtitle>

                <v-card-actions class="pb-5">
                    <v-spacer></v-spacer>
                    <v-btn
                        outlined
                        color="#61B15A"
                        style="text-transform: unset !important"
                        @click="confirmGenerate"
                    >
                        Yes
                    </v-btn>
                    <v-btn
                        color="#EB4646"
                        class="white--text"
                        style="text-transform: unset !important"
                        @click="closeGenerate"
                    >
                        No
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import axios from "axios";
import { mapGetters, mapActions } from "vuex";

export default {
    components: {},
    props: { modify: Boolean, addDataDialog: Boolean },
    watch: {},
    data: () => ({
        progress: false,
        dialogGenerate: false,
        dateMenu: false,
        valid: false,
        rules: {
            required: (value) => !!value || "Tidak boleh kosong !!",
        },
        manufacturing_date: null,
        modalDialog: false,
        modalTitle: "Error Message",
        params: {
            page: 1,
            per_page: 10,
            search: "",
        },
        form: {
            kode: "",
            satuan: "",
            mat_code: "",
            manufacturing_date: "",
            shelf_life: 24,
            expired_date: "",
            total_mc: "",
            total_ic: 0,
            total_capsule: 0,
            total_box_sleeve: 0,
        },
        errors: {},

        listMatCode: [],
        listMasaKadaluarsa: [
            { id: 12, text: "12" },
            { id: 24, text: "24" },
        ],
        IC_IN_MC: {
            BOX: 12,
            SLV: 16,
        },
        PACKAGE_IN_IC: {
            SLV: 15,
            BOX: 10,
        },
        CAPSULE_IN_PKG: {
            SLV: 10,
            BOX: 30,
        },

        apiUrl: process.env.VUE_APP_API_URL,
    }),

    computed: {
        ...mapGetters({
            user: "auth/user",
        }),
        calculateCapsule() {
            return (
                this.form.total_box_sleeve *
                this.CAPSULE_IN_PKG[this.form.satuan]
            );
        },
    },

    methods: {
        ...mapActions({
            refreshAction: "auth/refresh",
        }),
        applyDateChange() {
            const [year, month, date] = this.manufacturing_date.split("-");
            this.form.manufacturing_date = [date, month, year].join("-");
            this.dateExpiredChange();
        },
        dateExpiredChange() {
            if (!this.form.manufacturing_date || !this.form.shelf_life) return;

            const date = new Date(this.manufacturing_date);
            const total = date.getMonth() + Number(this.form.shelf_life);
            date.setMonth(total);

            const result = date.toLocaleString("fr-ca").substring(0, 10);
            const [year, month, dt] = result.split("-");
            this.form.expired_date = [dt, month, year].join("-");
        },
        formatDateLocaleToDefault(date) {
            try {
                const [dt, month, year] = date.split("-");
                return `${year}-${month}-${dt}`;
            } catch (error) {
                return date;
            }
        },
        autoDisplayPackage(val) {
            this.errors.total_box_sleeve = "";
            if (typeof val == "object") {
                this.form.kode = val.kode;
                this.form.satuan = val.satuan;
                this.form.mat_code = val.mat_code;
            }

            const { satuan, total_box_sleeve } = this.form;
            if (total_box_sleeve % this.PACKAGE_IN_IC[satuan]) {
                this.form.total_ic = 0;
                this.form.total_mc = 0;
                this.form.total_capsule = 0;

                this.errors.total_box_sleeve = `Input harus kelipatan ${this.PACKAGE_IN_IC[satuan]}`;
            } else if (satuan && total_box_sleeve) {
                const boxToIc = total_box_sleeve / this.PACKAGE_IN_IC[satuan];
                const ic = boxToIc % this.IC_IN_MC[satuan];

                this.form.total_ic = ic;
                this.form.total_mc = (boxToIc - ic) / this.IC_IN_MC[satuan];
                this.form.total_capsule = this.calculateCapsule;
            }
        },
        closeGenerate() {
            this.dialogGenerate = false;
        },
        confirmGenerate() {
            this.postData();
        },
        closeModalDialog() {
            this.modalDialog = false;
        },
        errorHandler(error) {
            const { response } = error;

            let msg = "";
            if (response.data?.data?.details) {
                msg = "Pastikan input sudah terisi dengan benar!";
                this.errors = {};
                response.data?.data?.details.forEach((v) => {
                    this.errors[v.context.key] = v.message;
                });
            } else if (response?.data) {
                const { errMessage, message } = response.data;
                msg = message || errMessage || "Error Uncaught";
            } else {
                msg = error?.message;
            }

            this.modalDialog = true;
            this.modalTitle = msg;
        },
        async getDataMaterial() {
            try {
                const { data } = await axios.get("v1/material", {
                    params: {
                        ...this.params,
                        satuan: ["BOX", "SLV"],
                        mat_code: ["MT10", "MT30"],
                        is_activated: 1,
                    },
                });

                this.listMatCode = data?.data?.rows || [];
            } catch (error) {
                this.errorHandler(error);
            }
        },

        async postData() {
            try {
                this.closeGenerate();
                this.progress = true;

                const body = { ...this.form };
                body["manufacturing_date"] = this.formatDateLocaleToDefault(
                    body.manufacturing_date
                );
                body["expired_date"] = this.formatDateLocaleToDefault(
                    body.expired_date
                );

                await axios.post("v1/barcode", body);

                this.modalDialog = true;
                this.modalTitle = "Berhasil menyimpan data";

                setTimeout(() => {
                    this.closeModalDialog();
                    this.$emit("closeDataDialog");
                    this.$emit("successGenerate");
                }, 1000);
            } catch (error) {
                this.errorHandler(error);
            } finally {
                this.progress = false;
            }
        },
    },

    created() {
        if (this.response_headers.mc_to_ic_box) {
            this.IC_IN_MC.BOX = this.response_headers.mc_to_ic_box;
            this.IC_IN_MC.SLV = this.response_headers.mc_to_ic_slv;
            this.PACKAGE_IN_IC.BOX = this.response_headers.ic_to_box;
            this.PACKAGE_IN_IC.SLV = this.response_headers.ic_to_slv;
            this.CAPSULE_IN_PKG.BOX = this.response_headers.box_to_capsule;
            this.CAPSULE_IN_PKG.SLV = this.response_headers.slv_to_capsule;
        }

        this.rules.message = (value) => !!value || "Tidak boleh kosong yaa !!";
    },
    mounted() {
        this.getDataMaterial();
    },
};
</script>
