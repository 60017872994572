<template>
    <div class="px-4 pb-6">
        <v-container fluid class="px-0 d-print-none">
            <v-card outlined elevation="3" class="px-4 py-4">
                <v-card-text>
                    <v-data-table
                        class="black--text"
                        :headers="headers"
                        :items="items"
                        :items-per-page="itemsPerPage"
                        :items-length="itemCount"
                        :loading="loading"
                        :page.sync="page"
                        hide-default-footer
                        dense
                    >
                        <template v-slot:loading>
                            <v-skeleton-loader
                                type="table-row@5"
                            ></v-skeleton-loader>
                        </template>

                        <template v-slot:top>
                            <v-toolbar flat dense class="mb-5">
                                <v-row>
                                    <v-col md="8">
                                        <v-text-field
                                            outlined
                                            dense
                                            v-model="search"
                                            autocomple="off"
                                            placeholder="Search..."
                                            hide-details
                                            append-icon="mdi-magnify"
                                            :prepend-inner-icon="
                                                prependIconSearch
                                            "
                                            @click:append="handleSearchData"
                                            @click:prepend-inner="clearSearch"
                                            v-on:keyup.enter="handleSearchData"
                                        >
                                        </v-text-field>
                                    </v-col>
                                    <v-col md="4" align="right">
                                        <v-btn
                                            color="#61B15A"
                                            class="text-subtitle-2 white--text"
                                            style="text-transform: unset !important"
                                            @click="dialogFormBarcode = true"
                                        >
                                            <v-icon left
                                                >mdi-plus-circle</v-icon
                                            >
                                            Generate Barcode
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-toolbar>
                        </template>

                        <template v-slot:item.total_box_sleeve="{ item }">
                            {{ `${item.total_box_sleeve} ${item.satuan}` }}
                        </template>

                        <template v-slot:item.actions="{ item }">
                            <v-btn
                                color="#FFA900"
                                small
                                class="text-caption white--text mr-2"
                                style="text-transform: unset !important"
                                :data="item"
                                @click="goToDetail(item)"
                            >
                                <v-icon left>mdi-eye-outline</v-icon>
                                Detail
                            </v-btn>
                            <v-btn
                                color="#61B15A"
                                small
                                class="text-caption white--text mr-2"
                                style="text-transform: unset !important"
                                :data="item"
                                @click="confirmGenerate(item)"
                            >
                                <v-icon left>mdi-download</v-icon>
                                CSV
                            </v-btn>
                        </template>
                    </v-data-table>
                </v-card-text>
                <div
                    class="d-flex mt-5 px-4 justify-space-between align-center"
                >
                    <v-row>
                        <v-col cols="2">
                            <v-select
                                v-model="itemsPerPage"
                                :items="showPerPage"
                                outlined
                                dense
                                label="items per page"
                                v-on:change="changeItemPerPage"
                            >
                            </v-select>
                        </v-col>
                        <v-col align="right">
                            <span class="mr-15 text-caption"
                                >Page: {{ page }}</span
                            >
                            <v-btn
                                :disabled="page <= 1"
                                fab
                                x-small
                                depressed
                                color="primary"
                                class="mx-3"
                                @click="prevPage"
                            >
                                <v-icon dark> mdi-chevron-left </v-icon>
                            </v-btn>
                            <v-btn
                                :disabled="page >= totalPage"
                                fab
                                x-small
                                depressed
                                color="primary"
                                class="mx-3"
                                @click="nextPage"
                            >
                                <v-icon dark> mdi-chevron-right </v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </div>
            </v-card>
        </v-container>

        <form-barcode
            v-if="dialogFormBarcode"
            :addDataDialog="dialogFormBarcode"
            @closeDataDialog="dialogFormBarcode = false"
            @successGenerate="refreshData"
        />

        <v-dialog v-model="progress" width="500">
            <v-card>
                <v-card-text class="pt-5">
                    <v-progress-linear
                        indeterminate
                        color="#FFA900"
                    ></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog persistent v-model="errorDialog" width="430">
            <v-card color="white" rounded>
                <v-card-title class="text-h5 black--text">
                    {{ errorTitle }}
                </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="light-green darken-1"
                        class="white--text"
                        @click="errorDialog = false"
                    >
                        Tutup
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="modalDialog" persistent max-width="500px">
            <v-card class="d-print-none">
                <v-card-title class="pa-2 text-h6 font-weight-bold black--text">
                    Confirmation
                </v-card-title>
                <v-divider></v-divider>
                <v-card-subtitle class="pa-4 text-subtitle-2 black--text">
                    {{ getMessageConfirm }}
                </v-card-subtitle>

                <v-card-actions class="pb-5">
                    <v-spacer></v-spacer>
                    <v-btn
                        outlined
                        color="#61B15A"
                        style="text-transform: unset !important"
                        @click="actionProses"
                    >
                        Yes
                    </v-btn>
                    <v-btn
                        color="#EB4646"
                        class="white--text"
                        style="text-transform: unset !important"
                        @click="closeModalDialog"
                    >
                        No
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import formBarcode from "./generate.vue";

export default {
    components: { formBarcode },
    props: { modify: Boolean },

    data: () => ({
        loading: false,
        progress: false,
        modalDialog: false,
        dialogFormBarcode: false,

        errorDialog: false,
        errorTitle: "Error Message",

        params: {
            page: 1,
            per_page: 10,
            search: "",
        },
        headers: [
            {
                text: "Action",
                class: "text-subtitle-2 font-weight-bold black--text",
                value: "actions",
                width: "205px",
            },
            {
                text: "Material",
                class: "text-subtitle-2 font-weight-bold black--text",
                value: "material.desc",
                width: "150px",
            },
            {
                text: "Batch",
                class: "text-subtitle-2 font-weight-bold black--text",
                value: "batch",
                width: "150px",
            },
            {
                text: "Date",
                class: "text-subtitle-2 font-weight-bold black--text",
                value: "manufacturing_date",
                width: "150px",
            },
            {
                text: "Exp Date",
                class: "text-subtitle-2 font-weight-bold black--text",
                value: "expired_date",
                width: "150px",
            },
            {
                text: "Total MC",
                class: "text-subtitle-2 font-weight-bold black--text",
                value: "total_mc",
                width: "150px",
            },
            {
                text: "Total IC",
                class: "text-subtitle-2 font-weight-bold black--text",
                value: "total_ic",
                width: "150px",
            },
            {
                text: "Box/Sleeve",
                class: "text-subtitle-2 font-weight-bold black--text",
                value: "total_box_sleeve",
                width: "150px",
            },
            {
                text: "Total Capsule",
                class: "text-subtitle-2 font-weight-bold black--text",
                value: "total_capsule",
                width: "150px",
            },
        ],
        actionType: "",
        search: "",
        page: 1,
        itemsPerPage: 10,
        itemCount: 1,
        showPerPage: [5, 10, 50, 100],
        items: [],
        currentItem: null,
        prependIconSearch: "",
        apiUrl: process.env.VUE_APP_API_URL,
    }),

    computed: {
        ...mapGetters({
            user: "auth/user",
        }),
        getMessageConfirm() {
            switch (this.actionType) {
                case "generate":
                    return "Download CSV ?";
                default:
                    return "Default Message";
            }
        },
        totalPage() {
            return Math.ceil(this.itemCount / this.itemsPerPage);
        },
    },

    methods: {
        ...mapActions({
            refreshAction: "auth/refresh",
        }),
        itemPerpage(i, perpage) {
            if (perpage == 2) return true;
            if (perpage) return false;
            return i > 0 && (i + 1) % perpage == 0;
        },
        goToDetail(item) {
            this.$router.push({
                name: "Transaksi_Print_Barcode_Detail",
                params: { id: item.id },
            });
        },
        confirmGenerate(item) {
            this.currentItem = item;
            this.modalDialog = true;
            this.actionType = "generate";
        },
        closeModalDialog() {
            this.modalDialog = false;
            this.actionType = "";
        },
        actionProses() {
            switch (this.actionType) {
                case "generate":
                    this.generateCSV();
                    break;
                default:
                    break;
            }
        },
        prevPage() {
            if (this.page <= 1) return;
            this.page = this.page - 1;
            this.refreshData();
        },
        nextPage() {
            if (this.page >= this.totalPage) return;
            this.page = this.page + 1;
            this.refreshData();
        },
        changeItemPerPage() {
            this.page = 1;
            this.refreshData();
        },
        refreshData() {
            this.params.page = this.page;
            this.params.search = this.search || "";
            this.params.per_page = this.itemsPerPage;
            this.getDataBarcode();
        },
        handleSearchData() {
            if (this.search === null) {
                this.clearSearch();
            } else {
                this.prependIconSearch = "mdi-close";
                this.page = 1;
                this.data = [];

                this.refreshData();
            }
        },
        clearSearch() {
            this.search = null;
            this.prependIconSearch = "";
            this.data = [];

            this.refreshData();
        },
        errorHandler(error) {
            const { response } = error;

            let msg = "";
            if (response?.data) {
                const { message } = response.data;
                msg = message;
            } else {
                msg = error?.message;
            }

            this.errorDialog = true;
            this.errorTitle = msg;
        },
        async generateCSV() {
            try {
                const { id } = this.currentItem;
                const URL = this.apiUrl + `v1/barcode/${id}/export?type=csv`;
                const link = document.createElement("a");
                link.href = URL;

                link.setAttribute("download", true);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } catch (error) {
                this.errorHandler(error);
            } finally {
                this.closeModalDialog();
            }
        },
        async getDataBarcode() {
            try {
                this.loading = true;
                const { data } = await axios.get("v1/barcode", {
                    params: this.params,
                });

                this.items = data?.data?.rows || [];
                this.itemCount = data?.data?.count || 1;
            } catch (error) {
                this.errorHandler(error);
            } finally {
                this.loading = false;
            }
        },
    },

    created() {},

    mounted() {
        this.getDataBarcode();
    },
};
</script>
<style scoped></style>
