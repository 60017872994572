<template>
    <div class="px-4 pb-6">
        <v-container fluid class="px-0 d-print-none">
            <v-card outlined elevation="3" class="px-4 py-4">
                <v-card-text>
                    <v-data-table
                        class="black--text"
                        :headers="headers"
                        :items="items"
                        :items-per-page="itemsPerPage"
                        :loading="loading"
                        :page.sync="page"
                        hide-default-footer
                        show-select
                        v-model="selected"
                        :single-select="singleSelect"
                        dense
                        :expanded.sync="expanded"
                        :single-expand="false"
                        show-expand
                    >
                        <template v-slot:loading>
                            <v-skeleton-loader
                                type="table-row@5"
                            ></v-skeleton-loader>
                        </template>

                        <template v-slot:top>
                            <v-toolbar flat dense class="mb-5">
                                <v-row>
                                    <v-col md="8">
                                        <v-text-field
                                            outlined
                                            dense
                                            v-model="search"
                                            autocomple="off"
                                            placeholder="Search..."
                                            hide-details
                                            append-icon="mdi-magnify"
                                            :prepend-inner-icon="
                                                prependIconSearch
                                            "
                                            @click:append="handleSearchData"
                                            @click:prepend-inner="clearSearch"
                                            v-on:keyup.enter="handleSearchData"
                                        >
                                        </v-text-field>
                                    </v-col>
                                    <v-col md="4" class="d-flex">
                                        <v-col class="pa-0">
                                            <v-btn
                                                :disabled="!selected.length"
                                                color="#61B15A"
                                                class="text-subtitle-2 white--text"
                                                style="text-transform: unset !important"
                                                @click="confirmPrint('print')"
                                            >
                                                <v-icon left
                                                    >mdi-printer</v-icon
                                                >
                                                Print Barcode
                                            </v-btn>
                                        </v-col>
                                    </v-col>
                                </v-row>
                            </v-toolbar>
                        </template>

                        <template v-slot:expanded-item="{ headers, item }">
                            <td :colspan="headers.length">
                                <section class="pa-4 ">
                                    <p class="ma-0 font-weight-bold">
                                        Barcode IC
                                    </p>
                                    <div
                                        style="display: grid; grid-template-columns: repeat(5, 1fr); gap: .5rem;"
                                    >
                                        <div
                                            v-for="(val,
                                            i) in item.barcode_detail"
                                            :key="i"
                                            class="px-4 py-2"
                                            style="border: 1px solid lightgray;"
                                        >
                                            <p class="ma-0 ">
                                                {{ val.barcode }}
                                            </p>
                                        </div>
                                    </div>
                                </section>
                            </td>
                        </template>

                        <template v-slot:item.material>
                            {{ data ? data.material.desc : "-" }}
                        </template>

                        <template v-slot:item.batch>
                            {{ data ? data.batch : "-" }}
                        </template>
                    </v-data-table>
                </v-card-text>
                <div
                    class="d-flex mt-5 px-4 justify-space-between align-center"
                >
                    <v-row>
                        <v-col cols="2">
                            <v-select
                                v-model="itemsPerPage"
                                :items="showPerPage"
                                outlined
                                dense
                                label="items per page"
                                v-on:change="changeItemPerPage"
                            >
                            </v-select>
                        </v-col>
                        <v-col align="right">
                            <span class="mr-15 text-caption"
                                >Page: {{ page }}</span
                            >
                            <v-btn
                                :disabled="page <= 1"
                                fab
                                x-small
                                depressed
                                color="primary"
                                class="mx-3"
                                @click="prevPage"
                            >
                                <v-icon dark> mdi-chevron-left </v-icon>
                            </v-btn>
                            <v-btn
                                :disabled="page >= totalPage"
                                fab
                                x-small
                                depressed
                                color="primary"
                                class="mx-3"
                                @click="nextPage"
                            >
                                <v-icon dark> mdi-chevron-right </v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </div>
            </v-card>
        </v-container>

        <v-dialog v-model="progress" width="500">
            <v-card>
                <v-card-text class="pt-5">
                    <v-progress-linear
                        indeterminate
                        color="#FFA900"
                    ></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog persistent v-model="errorDialog" width="430">
            <v-card color="white" rounded>
                <v-card-title class="text-h5 black--text">
                    {{ errorTitle }}
                </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="light-green darken-1"
                        class="white--text"
                        @click="errorDialog = false"
                    >
                        Tutup
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogPrint" persistent max-width="500px">
            <v-card class="d-print-none">
                <v-card-title class="pa-2 text-h6 font-weight-bold black--text">
                    Confirmation
                </v-card-title>
                <v-divider></v-divider>
                <v-card-subtitle class="pa-4 text-subtitle-2 black--text">
                    {{ getMessageConfirm }}
                </v-card-subtitle>

                <v-card-actions class="pb-5">
                    <v-spacer></v-spacer>
                    <v-btn
                        outlined
                        color="#61B15A"
                        style="text-transform: unset !important"
                        @click="actionProses"
                    >
                        Yes
                    </v-btn>
                    <v-btn
                        color="#EB4646"
                        class="white--text"
                        style="text-transform: unset !important"
                        @click="closePrint"
                    >
                        No
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <div id="print-section" :style="{ '--cols': MC_PERPAGE }">
            <div
                v-for="(item, index) in selected"
                :key="index + JSON.stringify(item)"
                class="section-container"
                :class="(index + 1) % MC_PERPAGE == 0 ? 'break-page' : ''"
            >
                <div class="ic-container">
                    <div
                        class="barcode-ic"
                        v-for="(itemIC, i) in item.barcode_detail"
                        :key="i"
                    >
                        <vue-barcode
                            :displayValue="true"
                            :value="itemIC.barcode"
                            textAlign="left"
                            fontSize="10px"
                            :width="1"
                            :height="40"
                        ></vue-barcode>
                    </div>
                </div>

                <div class="barcode-mc mb-14">
                    <vue-barcode
                        :displayValue="true"
                        :value="item.barcode"
                        textAlign="left"
                        fontSize="20px"
                        :width="2"
                        :height="120"
                    ></vue-barcode>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import VueBarcode from "vue-barcode";

export default {
    components: { VueBarcode },
    props: { modify: Boolean },

    data: () => ({
        loading: false,
        progress: false,
        dialogPrint: false,

        errorDialog: false,
        errorTitle: "Error Message",

        headers: [
            {
                text: "Material",
                class: "text-subtitle-2 font-weight-bold black--text",
                value: "material",
                width: "150px",
            },
            {
                text: "Barcode MC",
                class: "text-subtitle-2 font-weight-bold black--text",
                value: "barcode",
                width: "150px",
            },
            {
                text: "Batch",
                class: "text-subtitle-2 font-weight-bold black--text",
                value: "batch",
                width: "150px",
            },
            { text: "", value: "data-table-expand" },
        ],
        expanded: [],
        singleExpand: false,
        actionType: "",
        singleSelect: false,
        selected: [],
        MC_PERPAGE: 2,
        search: "",
        page: 1,
        itemsPerPage: 10,
        showPerPage: [5, 10, 50, 100],
        items: [],
        data: null,
        currentItem: null,
        prependIconSearch: "",
    }),

    computed: {
        ...mapGetters({
            user: "auth/user",
        }),
        getMessageConfirm() {
            switch (this.actionType) {
                case "print":
                    return "Print Barcode ?";
                default:
                    return "Default Message";
            }
        },
        totalPage() {
            return Math.ceil(this.items.length / this.itemsPerPage);
        },
    },

    methods: {
        ...mapActions({
            refreshAction: "auth/refresh",
        }),
        itemPerpage(i, perpage) {
            if (perpage == 2) return true;
            if (perpage) return false;
            return i > 0 && (i + 1) % perpage == 0;
        },
        closePrint() {
            this.dialogPrint = false;
            this.actionType = "";
        },
        confirmPrint(type) {
            this.dialogPrint = true;
            this.actionType = type;
        },
        actionProses() {
            switch (this.actionType) {
                case "print":
                    this.handlePrintPreview();
                    break;
                default:
                    break;
            }
        },
        handlePrintPreview() {
            this.closePrint();
            setTimeout(() => {
                window.print();
            }, 0);
        },

        prevPage() {
            if (this.page <= 1) return;
            this.page = this.page - 1;
        },
        nextPage() {
            if (this.page >= this.totalPage) return;
            this.page = this.page + 1;
        },
        changeItemPerPage() {
            this.page = 1;
        },
        handleSearchData() {
            if (this.search === null) {
                this.clearSearch();
            } else {
                this.prependIconSearch = "mdi-close";
                this.page = 1;
                this.filterItems();
            }
        },
        clearSearch() {
            this.search = null;
            this.prependIconSearch = "";
            this.items = this.data.barcode;
        },
        filterItems() {
            const regexp = new RegExp(this.search, "i");
            const result = [];
            this.data.barcode.forEach((v) => {
                if (regexp.test(v.barcode)) {
                    result.push(v);
                }
            });
            this.items = result;
        },

        errorHandler(error) {
            const { response } = error;

            let msg = "";
            if (response?.data) {
                const { message, errMessage } = response.data;
                msg = message || errMessage;
            } else {
                msg = error?.message;
            }

            this.errorDialog = true;
            this.errorTitle = msg;
        },

        async getDetailBarcodeById() {
            try {
                this.loading = true;
                const { data } = await axios.get("v1/barcode/" + this.id);
                this.data = data?.data;
                this.items = data?.data?.barcode || [];
            } catch (error) {
                this.errorHandler(error);
            } finally {
                this.loading = false;
            }
        },
    },

    created() {},

    mounted() {
        const { id } = this.$route.params;
        this.id = id;
        this.getDetailBarcodeById();
    },
};
</script>
<style scoped>
@media print {
    body {
        margin: 0 !important;
        padding: 0 !important;
    }

    @page {
        size: A3 portrait;
        margin: 4cm auto;
    }

    .break-page,
    section {
        page-break-after: always;
        break-after: page;
    }

    #print-section {
        display: grid !important;
        grid-template-columns: repeat(--cols, 1fr);
        z-index: 9999 !important;
        margin: auto;
    }
    .section-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .ic-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

    .barcode-ic,
    .barcode-mc {
        margin: 5mm;
        padding: 1mm;
        border: 1px solid black;
        display: inline-block;
    }
    .barcode-ic {
        width: 50mm;
        height: 20mm;
    }
    .barcode-mc {
        width: 100mm;
        height: 50mm;
    }

    .barcode-ic > div,
    .barcode-mc > div {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

#print-section {
    display: none;
    grid-template-columns: repeat(var(--cols), 1fr);
    margin: auto;
}
.section-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.ic-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.barcode-ic,
.barcode-mc {
    margin: 5mm;
    padding: 1mm;
    border: 1px solid black;
    display: inline-block;
}
.barcode-ic {
    width: 50mm;
    height: 20mm;
}
.barcode-mc {
    width: 100mm;
    height: 50mm;
}

.barcode-ic > div,
.barcode-mc > div {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
