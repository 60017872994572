<template>
    <div>
        <v-dialog persistent max-width="700" v-model="addDataDialog">
            <v-card>
                <v-card-title class="text-h6 font-weight-bold">
                    Tambah Role
                </v-card-title>

                <v-divider class="mx-5"></v-divider>

                <v-card-text class="pb-0 mt-3">
                    <v-form ref="form" v-model="valid">
                        <v-card-title
                            class="pa-0 text-subtitle-1 font-weight-bold black--text"
                        >
                            Nama Role
                        </v-card-title>
                        <v-text-field
                            v-model="dataRole.name"
                            outlined
                            dense
                            :rules="[rules.required]"
                            autocomplete="off"
                        ></v-text-field>

                        <v-card-title
                            class="pa-0 text-subtitle-1 font-weight-bold black--text"
                        >
                            Deskripsi
                        </v-card-title>
                        <v-text-field
                            v-model="dataRole.desc"
                            outlined
                            dense
                            :rules="[rules.required]"
                            autocomplete="off"
                        ></v-text-field>

                        <v-card-title
                            class="pa-0 text-subtitle-1 font-weight-bold black--text"
                        >
                            Tipe Role
                        </v-card-title>
                        <v-select
                            v-model="dataRole.is_user_web"
                            dense
                            outlined
                            :items="listTipeRole"
                            item-text="text"
                            item-value="id"
                            :rules="[rules.required]"
                            v-on:change="changeTipeRole"
                        >
                        </v-select>

                        <v-divider class="mb-5"></v-divider>

                        <v-card-title
                            class="pa-0 text-subtitle-1 font-weight-bold black--text"
                        >
                            Set Akses Page Master Data
                        </v-card-title>
                        <template v-if="dataRole.is_user_web === 2">
                            <v-row dense>
                                <v-col class="pb-0">
                                    <v-checkbox
                                        class="pa-0"
                                        dense
                                        hide-details
                                        label="Penerimaan"
                                        value="Penerimaan"
                                        v-model="listPermission"
                                    ></v-checkbox>
                                </v-col>
                                <v-col class="pb-0">
                                    <v-checkbox
                                        class="pa-0"
                                        dense
                                        hide-details
                                        label="Pengeluaran"
                                        value="Pengeluaran"
                                        v-model="listPermission"
                                    ></v-checkbox>
                                </v-col>
                                <v-col class="pb-0">
                                    <v-checkbox
                                        class="pa-0"
                                        dense
                                        hide-details
                                        label="Outpacking"
                                        value="Outpack"
                                        v-model="listPermission"
                                    ></v-checkbox>
                                </v-col>
                                <v-col class="pb-0">
                                    <v-checkbox
                                        class="pa-0"
                                        dense
                                        hide-details
                                        label="Counting"
                                        value="Counting"
                                        v-model="listPermission"
                                    ></v-checkbox>
                                </v-col>
                            </v-row>
                        </template>
                        <template v-if="dataRole.is_user_web === 1">
                            <v-card-title
                                class="pa-0 text-subtitle-2 font-weight-bold black--text"
                            >
                                Transaksi :
                            </v-card-title>
                            <v-row dense>
                                <v-col cols="6" class="pb-0">
                                    <v-card-title
                                        class="pa-0 text-subtitle-2 black--text"
                                    >
                                        Terima Data
                                    </v-card-title>
                                </v-col>
                                <v-col cols="3" class="pb-0">
                                    <v-checkbox
                                        class="pa-0"
                                        dense
                                        hide-details
                                        label="modify"
                                        value="company_in.modify"
                                        v-model="listPermission"
                                    ></v-checkbox>
                                </v-col>
                                <v-col cols="3" class="pb-0">
                                    <v-checkbox
                                        class="pa-0"
                                        dense
                                        hide-details
                                        label="display"
                                        value="company_in.display"
                                        v-model="listPermission"
                                    ></v-checkbox>
                                </v-col>
                            </v-row>
                            <v-row dense>
                                <v-col cols="6" class="pb-0">
                                    <v-card-title
                                        class="pa-0 text-subtitle-2 black--text"
                                    >
                                        Surat Jalan Antar Gudang
                                    </v-card-title>
                                </v-col>
                                <v-col cols="3" class="pb-0">
                                    <v-checkbox
                                        class="pa-0"
                                        dense
                                        hide-details
                                        label="modify"
                                        value="surat_gudang.modify"
                                        v-model="listPermission"
                                    ></v-checkbox>
                                </v-col>
                                <v-col cols="3" class="pb-0">
                                    <v-checkbox
                                        class="pa-0"
                                        dense
                                        hide-details
                                        label="display"
                                        value="surat_gudang.display"
                                        v-model="listPermission"
                                    ></v-checkbox>
                                </v-col>
                            </v-row>
                            <v-row dense>
                                <v-col cols="6" class="pb-0">
                                    <v-card-title
                                        class="pa-0 text-subtitle-2 black--text"
                                    >
                                        Surat Jalan Sales
                                    </v-card-title>
                                </v-col>
                                <v-col cols="3" class="pb-0">
                                    <v-checkbox
                                        class="pa-0"
                                        dense
                                        hide-details
                                        label="modify"
                                        value="surat_sales.modify"
                                        v-model="listPermission"
                                    ></v-checkbox>
                                </v-col>
                                <v-col cols="3" class="pb-0">
                                    <v-checkbox
                                        class="pa-0"
                                        dense
                                        hide-details
                                        label="display"
                                        value="surat_sales.display"
                                        v-model="listPermission"
                                    ></v-checkbox>
                                </v-col>
                            </v-row>
                            <v-row dense>
                                <v-col cols="6" class="pb-0">
                                    <v-card-title
                                        class="pa-0 text-subtitle-2 black--text"
                                    >
                                        Surat Jalan Sampel
                                    </v-card-title>
                                </v-col>
                                <v-col cols="3" class="pb-0">
                                    <v-checkbox
                                        class="pa-0"
                                        dense
                                        hide-details
                                        label="modify"
                                        value="surat_sample.modify"
                                        v-model="listPermission"
                                    ></v-checkbox>
                                </v-col>
                                <v-col cols="3" class="pb-0">
                                    <v-checkbox
                                        class="pa-0"
                                        dense
                                        hide-details
                                        label="display"
                                        value="surat_sample.display"
                                        v-model="listPermission"
                                    ></v-checkbox>
                                </v-col>
                            </v-row>
                            <v-row dense>
                                <v-col cols="6" class="pb-0">
                                    <v-card-title
                                        class="pa-0 text-subtitle-2 black--text"
                                    >
                                        Surat Jalan Donasi
                                    </v-card-title>
                                </v-col>
                                <v-col cols="3" class="pb-0">
                                    <v-checkbox
                                        class="pa-0"
                                        dense
                                        hide-details
                                        label="modify"
                                        value="surat_donasi.modify"
                                        v-model="listPermission"
                                    ></v-checkbox>
                                </v-col>
                                <v-col cols="3" class="pb-0">
                                    <v-checkbox
                                        class="pa-0"
                                        dense
                                        hide-details
                                        label="display"
                                        value="surat_donasi.display"
                                        v-model="listPermission"
                                    ></v-checkbox>
                                </v-col>
                            </v-row>
                            <v-row dense>
                                <v-col cols="6" class="pb-0">
                                    <v-card-title
                                        class="pa-0 text-subtitle-2 black--text"
                                    >
                                        Surat Jalan Event
                                    </v-card-title>
                                </v-col>
                                <v-col cols="3" class="pb-0">
                                    <v-checkbox
                                        class="pa-0"
                                        dense
                                        hide-details
                                        label="modify"
                                        value="surat_event.modify"
                                        v-model="listPermission"
                                    ></v-checkbox>
                                </v-col>
                                <v-col cols="3" class="pb-0">
                                    <v-checkbox
                                        class="pa-0"
                                        dense
                                        hide-details
                                        label="display"
                                        value="surat_event.display"
                                        v-model="listPermission"
                                    ></v-checkbox>
                                </v-col>
                            </v-row>
                            <v-row dense>
                                <v-col cols="6" class="pb-0">
                                    <v-card-title
                                        class="pa-0 text-subtitle-2 black--text"
                                    >
                                        Stock Opname
                                    </v-card-title>
                                </v-col>
                                <v-col cols="3" class="pb-0">
                                    <v-checkbox
                                        class="pa-0"
                                        dense
                                        hide-details
                                        label="modify"
                                        value="stok_opname.modify"
                                        v-model="listPermission"
                                    ></v-checkbox>
                                </v-col>
                                <v-col cols="3" class="pb-0">
                                    <v-checkbox
                                        class="pa-0"
                                        dense
                                        hide-details
                                        label="display"
                                        value="stok_opname.display"
                                        v-model="listPermission"
                                    ></v-checkbox>
                                </v-col>
                            </v-row>
                            <v-row dense>
                                <v-col cols="6" class="pb-0">
                                    <v-card-title
                                        class="pa-0 text-subtitle-2 black--text"
                                    >
                                        TP Gd. Retur ke Gd. Stock
                                    </v-card-title>
                                </v-col>
                                <v-col cols="3" class="pb-0">
                                    <v-checkbox
                                        class="pa-0"
                                        dense
                                        hide-details
                                        label="modify"
                                        value="retur_stock.modify"
                                        v-model="listPermission"
                                    ></v-checkbox>
                                </v-col>
                                <v-col cols="3" class="pb-0">
                                    <v-checkbox
                                        class="pa-0"
                                        dense
                                        hide-details
                                        label="display"
                                        value="retur_stock.display"
                                        v-model="listPermission"
                                    ></v-checkbox>
                                </v-col>
                            </v-row>
                            <v-card-title
                                class="pa-0 pt-5 text-subtitle-2 font-weight-bold black--text"
                            >
                                Master Data :
                            </v-card-title>
                            <v-row dense>
                                <v-col cols="6" class="pb-0">
                                    <v-card-title
                                        class="pa-0 text-subtitle-2 black--text"
                                    >
                                        Gudang
                                    </v-card-title>
                                </v-col>
                                <v-col cols="3" class="pb-0">
                                    <v-checkbox
                                        class="pa-0"
                                        dense
                                        hide-details
                                        label="modify"
                                        value="gudang.modify"
                                        v-model="listPermission"
                                    ></v-checkbox>
                                </v-col>
                                <v-col cols="3" class="pb-0">
                                    <v-checkbox
                                        class="pa-0"
                                        dense
                                        hide-details
                                        label="display"
                                        value="gudang.display"
                                        v-model="listPermission"
                                    ></v-checkbox>
                                </v-col>
                            </v-row>
                            <v-row dense>
                                <v-col cols="6" class="pb-0">
                                    <v-card-title
                                        class="pa-0 text-subtitle-2 black--text"
                                    >
                                        Tipe Box
                                    </v-card-title>
                                </v-col>
                                <v-col cols="3" class="pb-0">
                                    <v-checkbox
                                        class="pa-0"
                                        dense
                                        hide-details
                                        label="modify"
                                        value="box_type.modify"
                                        v-model="listPermission"
                                    ></v-checkbox>
                                </v-col>
                                <v-col cols="3" class="pb-0">
                                    <v-checkbox
                                        class="pa-0"
                                        dense
                                        hide-details
                                        label="display"
                                        value="box_type.display"
                                        v-model="listPermission"
                                    ></v-checkbox>
                                </v-col>
                            </v-row>
                            <v-row dense>
                                <v-col cols="6" class="pb-0">
                                    <v-card-title
                                        class="pa-0 text-subtitle-2 black--text"
                                    >
                                        User Web
                                    </v-card-title>
                                </v-col>
                                <v-col cols="3" class="pb-0">
                                    <v-checkbox
                                        class="pa-0"
                                        dense
                                        hide-details
                                        label="modify"
                                        value="web.modify"
                                        v-model="listPermission"
                                    ></v-checkbox>
                                </v-col>
                                <v-col cols="3" class="pb-0">
                                    <v-checkbox
                                        class="pa-0"
                                        dense
                                        hide-details
                                        label="display"
                                        value="web.display"
                                        v-model="listPermission"
                                    ></v-checkbox>
                                </v-col>
                            </v-row>
                            <v-row dense>
                                <v-col cols="6" class="pb-0">
                                    <v-card-title
                                        class="pa-0 text-subtitle-2 black--text"
                                    >
                                        User Barcode
                                    </v-card-title>
                                </v-col>
                                <v-col cols="3" class="pb-0">
                                    <v-checkbox
                                        class="pa-0"
                                        dense
                                        hide-details
                                        label="modify"
                                        value="barcode.modify"
                                        v-model="listPermission"
                                    ></v-checkbox>
                                </v-col>
                                <v-col cols="3" class="pb-0">
                                    <v-checkbox
                                        class="pa-0"
                                        dense
                                        hide-details
                                        label="display"
                                        value="barcode.display"
                                        v-model="listPermission"
                                    ></v-checkbox>
                                </v-col>
                            </v-row>
                            <v-row dense>
                                <v-col cols="6" class="pb-0">
                                    <v-card-title
                                        class="pa-0 text-subtitle-2 black--text"
                                    >
                                        Customer
                                    </v-card-title>
                                </v-col>
                                <v-col cols="3" class="pb-0">
                                    <v-checkbox
                                        class="pa-0"
                                        dense
                                        hide-details
                                        label="modify"
                                        value="customer.modify"
                                        v-model="listPermission"
                                    ></v-checkbox>
                                </v-col>
                                <v-col cols="3" class="pb-0">
                                    <v-checkbox
                                        class="pa-0"
                                        dense
                                        hide-details
                                        label="display"
                                        value="customer.display"
                                        v-model="listPermission"
                                    ></v-checkbox>
                                </v-col>
                            </v-row>
                            <v-row dense>
                                <v-col cols="6" class="pb-0">
                                    <v-card-title
                                        class="pa-0 text-subtitle-2 black--text"
                                    >
                                        Devices
                                    </v-card-title>
                                </v-col>
                                <v-col cols="3" class="pb-0">
                                    <v-checkbox
                                        class="pa-0"
                                        dense
                                        hide-details
                                        label="modify"
                                        value="devices.modify"
                                        v-model="listPermission"
                                    ></v-checkbox>
                                </v-col>
                                <v-col cols="3" class="pb-0">
                                    <v-checkbox
                                        class="pa-0"
                                        dense
                                        hide-details
                                        label="display"
                                        value="devices.display"
                                        v-model="listPermission"
                                    ></v-checkbox>
                                </v-col>
                            </v-row>
                            <v-row dense>
                                <v-col cols="6" class="pb-0">
                                    <v-card-title
                                        class="pa-0 text-subtitle-2 black--text"
                                    >
                                        Material
                                    </v-card-title>
                                </v-col>
                                <v-col cols="3" class="pb-0">
                                    <v-checkbox
                                        class="pa-0"
                                        dense
                                        hide-details
                                        label="modify"
                                        value="material.modify"
                                        v-model="listPermission"
                                    ></v-checkbox>
                                </v-col>
                                <v-col cols="3" class="pb-0">
                                    <v-checkbox
                                        class="pa-0"
                                        dense
                                        hide-details
                                        label="display"
                                        value="material.display"
                                        v-model="listPermission"
                                    ></v-checkbox>
                                </v-col>
                            </v-row>
                            <v-row dense>
                                <v-col cols="6" class="pb-0">
                                    <v-card-title
                                        class="pa-0 text-subtitle-2 black--text"
                                    >
                                        Signatuer
                                    </v-card-title>
                                </v-col>
                                <v-col cols="3" class="pb-0">
                                    <v-checkbox
                                        class="pa-0"
                                        dense
                                        hide-details
                                        label="modify"
                                        value="signature.modify"
                                        v-model="listPermission"
                                    ></v-checkbox>
                                </v-col>
                                <v-col cols="3" class="pb-0">
                                    <v-checkbox
                                        class="pa-0"
                                        dense
                                        hide-details
                                        label="display"
                                        value="signature.display"
                                        v-model="listPermission"
                                    ></v-checkbox>
                                </v-col>
                            </v-row>
                            <v-row dense>
                                <v-col cols="6" class="pb-0">
                                    <v-card-title
                                        class="pa-0 text-subtitle-2 black--text"
                                    >
                                        Role
                                    </v-card-title>
                                </v-col>
                                <v-col cols="3" class="pb-0">
                                    <v-checkbox
                                        class="pa-0"
                                        dense
                                        hide-details
                                        label="modify"
                                        value="role.modify"
                                        v-model="listPermission"
                                    ></v-checkbox>
                                </v-col>
                                <v-col cols="3" class="pb-0">
                                    <v-checkbox
                                        class="pa-0"
                                        dense
                                        hide-details
                                        label="display"
                                        value="role.display"
                                        v-model="listPermission"
                                    ></v-checkbox>
                                </v-col>
                            </v-row>
                        </template>
                        <template v-else> </template>
                    </v-form>
                </v-card-text>

                <v-card-actions class="pt-5 pb-5">
                    <v-spacer></v-spacer>
                    <v-btn
                        outlined
                        color="#61B15A"
                        style="text-transform: unset !important"
                        @click="closeOlahData"
                    >
                        Batal
                    </v-btn>
                    <v-btn
                        color="#61B15A"
                        class="white--text"
                        style="text-transform: unset !important"
                        @click="add"
                        :disabled="!valid"
                        :loading="loading"
                    >
                        Simpan
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog persistent v-model="popDialog" width="500">
            <v-card color="red" rounded>
                <v-card-title class="text-h6 white--text">
                    Akses Page master Data Tidak Boleh Kosong !!
                </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="light-green darken-1"
                        class="white--text"
                        @click="closePopDialog"
                        >Tutup</v-btn
                    >
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import axios from "axios";

export default {
    props: {
        addDataDialog: Boolean,
    },
    data: () => ({
        valid: false,
        loading: false,
        rules: {
            required: (value) => !!value || "Tidak boleh kosong !!",
        },
        listTipeRole: [
            { id: 1, text: "User Web CMS" },
            { id: 2, text: "User Barcode" },
        ],
        dataRole: {},
        dataPermission: {},
        typeUser: "",
        listPermission: [],
        popDialog: false,
    }),
    methods: {
        add() {
            this.loading = true;
            if (this.dataRole.is_user_web === 1) {
                this.typeUser = true;
            } else {
                this.typeUser = false;
            }
            if (this.listPermission.length === 0) {
                this.loading = false;
                this.popDialog = true;
            } else {
                const role = JSON.stringify(this.dataRole);
                axios
                    .post("v1/admin/role", role, {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    })
                    .then((response) => {
                        if (response.status === 200) {
                            var data = {};
                            data.id = response.data.data.roleId;
                            data.is_user_web = this.typeUser;
                            data.list_role = this.listPermission;
                            this.dataPermission = JSON.stringify(data);
                            axios
                                .put(
                                    "v1/admin/role/permission",
                                    this.dataPermission,
                                    {
                                        headers: {
                                            "Content-Type": "application/json",
                                        },
                                    }
                                )
                                .then((response) => {
                                    if (response.status === 201) {
                                        this.loading = false;
                                        this.$emit("reloadDataAdd");
                                        this.closeOlahData();
                                    }
                                });
                        }
                    });
            }
        },
        changeTipeRole() {
            this.listPermission = [];
        },
        closeOlahData() {
            this.loading = false;
            this.$refs.form.reset();
            this.$refs.form.resetValidation();
            this.$emit("closeAddDataDialog");
        },
        closePopDialog() {
            this.popDialog = false;
        },
    },
};
</script>
