<template>
    <div>
        <sidebar :id="id" :menu="menu" :submenu="submenu" />
        <v-container fluid>
            <template v-if="display">
                <LoadData :modify="modify" />
            </template>
            <template v-else>
                <v-alert dense outlined type="error">
                    Not Authorized !
                </v-alert>
            </template>
        </v-container>
    </div>
</template>

<script>
import Sidebar from "../../components/Sidebar.vue";
import LoadData from "../../components/Transaksi/barcode/print.vue";
import { mapGetters } from "vuex";

export default {
    name: "Transaksi_Print_Barcode_Detail",

    components: { Sidebar, LoadData },

    metaInfo: {
        title: "Rhea Admin",
        titleTemplate: "%s - Detail Barcode",
        htmlAttrs: {
            lang: "id",
            amp: true,
        },
    },

    data: () => ({
        id: "1",
        menu: "Transaksi",
        submenu: " -> Detail Barcode",
        display: true,
        modify: true,
    }),

    computed: {
        ...mapGetters({
            authenticated: "auth/authenticated",
            user: "auth/user",
        }),
    },

    created() {
        if (this.user.dataPermission.includes("print_barcode.display")) {
            this.display = true;
        } else {
            this.display = true;
        }
        if (this.user.dataPermission.includes("print_barcode.modify")) {
            this.modify = true;
        } else {
            this.modify = true;
        }
    },
};
</script>
