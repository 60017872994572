<template>
  <div>
    <v-container fluid class="px-0">
      <v-card outlined elevation="3">
        <v-card-text>
          <v-data-table
            class="black--text"
            :headers="headers"
            :search="search"
            :items="listDataRole"
            :items-per-page="itemsPerPage"
            @page-count="pageCount = $event"
            :page.sync="page"
            :loading="loading"
            hide-default-footer
            dense
          >
            <template v-slot:top>
              <v-toolbar flat dense class="mb-3">
                <v-row>
                  <v-col md="8">
                    <v-text-field
                      outlined
                      dense
                      v-model="search"
                      append-icon="mdi-magnify"
                      autocomple="off"
                      placeholder="Search..."
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col v-if="modify" md="4" align="right">
                    <v-btn
                      color="#61B15A"
                      class="text-subtitle-2 white--text"
                      style="text-transform: unset !important"
                      @click="addRole"
                    >
                      <v-icon left>mdi-plus-circle</v-icon>
                      Tambah Role
                    </v-btn>
                  </v-col>
                </v-row>
              </v-toolbar>
            </template>

            <template v-slot:item.is_user_web="{ item }">
              <span v-if="item.is_user_web === 1">User Web CMS</span>
              <span v-else>User Barcode</span>
            </template>

            <template v-if="modify" v-slot:item.actions="{ item }">
              <v-btn
                color="#FFA900"
                small
                class="text-caption white--text mr-2"
                style="text-transform: unset !important"
                @click.prevent="editRole(item.id)"
              >
                Edit
              </v-btn>
            </template>
          </v-data-table>
          <div class="text-center pt-2">
            <v-pagination
              v-model="page"
              :length="pageCount"
              @input="next"
            ></v-pagination>
          </div>
        </v-card-text>
      </v-card>
    </v-container>

    <v-dialog v-model="progress" width="500">
      <v-card>
        <v-card-text class="pt-5">
          <v-progress-linear indeterminate color="#FFA900"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <editData
      :editDataDialog="editDataDialog"
      :dataRole="dataRole"
      :dataPermission="dataPermission"
      :listPermissionEdit.sync="listPermissionEdit"
      @closeEditDataDialog="closeEditDataDialog"
      @reloadDataEdit="reloadDataEdit"
    />

    <add-data
      :addDataDialog="addDataDialog"
      @closeAddDataDialog="closeAddDataDialog"
      @reloadDataAdd="reloadDataAdd"
    />

    <v-dialog persistent v-model="popDialog" width="430">
      <v-card color="white" rounded>
        <v-card-title class="text-h5 black--text">
          {{ popTitle }}
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="light-green darken-1"
            class="white--text"
            @click="closePopDialog"
            >Tutup</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import axios from "axios";
import editData from "./editData.vue";
import AddData from "./addData.vue";

export default {
  components: { editData, AddData },
  props: { modify: Boolean },
  data: () => ({
    search: "",
    loading: false,
    headers: [
      {
        text: "Nama Role",
        class: "text-subtitle-2 font-weight-bold black--text",
        value: "name",
      },
      {
        text: "Deskripsi",
        class: "text-subtitle-2 font-weight-bold black--text",
        value: "desc",
      },
      {
        text: "Tipe Role",
        class: "text-subtitle-2 font-weight-bold black--text",
        value: "is_user_web",
      },
      {
        text: "Action",
        class: "text-subtitle-2 font-weight-bold black--text",
        value: "actions",
      },
    ],
    listDataRole: [],
    listPermissionBarcode: [],
    listPermissionWeb: [],
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    dataLength: "",
    editDataDialog: false,
    addDataDialog: false,
    dataRole: {},
    listPermissionEdit: [],
    dataPermission: [],
    progress: false,
    popDialog: false,
    popTitle: "",
  }),

  methods: {
    ...mapActions({
      refreshAction: "auth/refresh",
    }),

    getData() {
      if (this.listDataRole.length === 0) {
        this.loading = true;
      }
      axios
        .get(
          "v1/admin/role/list?limit=" + this.itemsPerPage + "&page=" + this.page
        )
        .then((response) => {
          this.loading = false;
          this.listDataRole = response.data.data;
          this.getDataLength();
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 401) {
              this.refreshAction(localStorage.getItem("refresh"));
              this.getData();
            }
            console.clear();
          }
        });
    },
    getDataLength() {
      axios.get("v1/admin/role/list?limit=200").then((response) => {
        this.dataLength = response.data.data.length;
        var count = response.data.data.length / this.itemsPerPage;
        if (count < 1) {
          this.pageCount = 0;
        } else {
          this.pageCount = Math.ceil(response.data.data.length / this.itemsPerPage);
        }
      });
    },
    next(page) {
      this.page = page;
      this.getData();
    },
    getPermission(id) {
      axios.get("v1/admin/role/permission/list?id=" + id).then((response) => {
        return response.data.data;
      });
    },
    getDataById(value) {
      axios
        .get("v1/admin/role?id=" + value)
        .then((response) => {
          const data = response.data.data.dataRole;
          if (data.is_user_web === 0) {
            data.is_user_web = 2;
          }
          this.dataRole = data;
          this.listPermissionEdit = response.data.data.dataPermission;
          if (response.data.isSucceed === true) {
            this.progress = false;
            this.editDataDialog = true;
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 401) {
              this.refreshAction(localStorage.getItem("refresh"));
              this.getData();
            }
            console.clear();
          }
        });
    },
    addRole() {
      this.addDataDialog = true;
    },
    editRole(id) {
      this.progress = true;
      this.getDataById(id);
    },
    reloadDataAdd() {
      this.popTitle = "Data Role berhasil ditambahkan";
      this.popDialog = true;
    },
    reloadDataEdit() {
      this.popTitle = "Data Role berhasil diperbaharui";
      this.popDialog = true;
    },
    closeAddDataDialog() {
      this.addDataDialog = false;
      this.getData();
    },
    closeEditDataDialog() {
      this.dataRole = {};
      this.dataPermission = [];
      this.listDataRole = [];
      this.editDataDialog = false;
      this.getData();
    },
    closePopDialog() {
      this.popTitle = "";
      this.popDialog = false;
    },
  },

  mounted() {
    this.getData();
  },
};
</script>